<template>
  <v-sheet class="md:fit-content">
    <div
      class="grid grid-cols-1 md:grid-cols-2 md:gap-4 md:divide-x lt-md:divide-y md:fit-content"
    >
      <v-date-picker
        v-model="date"
        :disabled="disabled"
        :readonly="readonly"
        :month="month"
        :min="minDate0"
        :max="maxDate0"
        :allowed-dates="allowedDates"
        :hide-weekdays="hideWeekdays"
        :bg-color="bgColor"
        :show-adjacent-months="showAdjacentMonths"
        :show-week="showWeek"
        :view-mode="viewMode"
        :weekdays="weekdays"
        :year="year"
        :hide-header="hideHeader"
        width="auto"
      />
      <v-time-picker
        v-model="time"
        :disabled="disabled"
        :readonly="readonly"
        :use-seconds="useSeconds"
        :format="timeFormat"
        :min="minTime0"
        :max="maxTime0"
        :ampm-in-title="ampmInTitle"
        :allowed-hours="allowedHours"
        :allowed-minutes="allowedMinutes"
        :allowed-seconds="allowedSeconds"
        :bg-color="bgColor"
        :hide-header="hideHeader"
        width="auto"
      />
    </div>
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import dayjs, { Dayjs } from "dayjs";

export default defineComponent({
  name: "DatetimePicker",
  props: {
    modelValue: String,
    title: { type: String, default: "Select date & time" },
    format: { type: String, default: "YYYY-MM-DD HH:mm:ss" },
    timeFormat: { type: String as PropType<"ampm" | "24hr">, default: "24hr" },
    viewMode: {
      type: String as PropType<"month" | "months" | "year">,
      default: "month",
    },
    weekdays: { type: Array as PropType<number[]> },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    useSeconds: { type: Boolean, default: false },
    hideHeader: { type: Boolean, default: false },
    hideWeekdays: { type: Boolean, default: false },
    ampmInTitle: { type: Boolean, default: false },
    showAdjacentMonths: { type: Boolean, default: false },
    showWeek: { type: Boolean, default: false },
    allowedDates: { type: Array as PropType<unknown[]> },
    allowedHours: { type: Array as PropType<number[]> },
    allowedMinutes: { type: Array as PropType<number[]> },
    allowedSeconds: { type: Array as PropType<number[]> },
    min: {},
    max: {},
    minDate: {},
    maxDate: {},
    minTime: { type: String },
    maxTime: { type: String },
    bgColor: { type: String },
    month: { type: [String, Number] },
    year: { type: [String, Number] },
  },
  computed: {
    date: {
      set(value: Dayjs | Date | string | undefined) {
        if (value && this.format) {
          value = dayjs(value).format(this.format);
        }
        this.$emit("update:modelValue", value);
      },
      get(): Dayjs | Date | string | undefined {
        if (this.modelValue && this.format) {
          return dayjs(this.modelValue, this.format).toDate();
        }

        return this.modelValue;
      },
    },
    time: {
      set(value: string | undefined) {
        // const date = this.getDate();
        this.date = this.getDatetime(value);
      },
      get() {
        if (this.modelValue) {
          const date = this.getDate();
          return this.getTime(date);
        }
      },
    },
    minDate0() {
      return this.minDate || this.extractDate(this.min);
    },
    maxDate0() {
      return this.maxDate || this.extractDate(this.max);
    },
    minTime0() {
      return this.minTime || this.extractTime(this.min);
    },
    maxTime0() {
      return this.maxTime || this.extractTime(this.max);
    },
  },
  methods: {
    getTime(date: Dayjs) {
      const format = this.useSeconds ? "HH:mm:ss" : "HH:mm";
      return date.format(format);
    },
    getDate() {
      const d = this.date;
      return d ? dayjs(d) : dayjs();
    },
    getDatetime(time?: string) {
      const date = this.getDate();
      const { hours, minutes, seconds } = parseTime(time);

      return date
        .startOf("day")
        .add(hours, "hours")
        .add(minutes, "minutes")
        .add(seconds, "seconds");
    },
    extractDate(value?: any) {
      if (value) {
        return dayjs(value).toDate();
      }
      return undefined;
    },
    extractTime(value?: any) {
      if (value) {
        const date = dayjs(value);
        return this.getTime(date);
      }
      return undefined;
    },
  },
});
</script>
